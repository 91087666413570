export const isBffEnabled = (pms: string) => {
  const bffEnabled = ["track", "escapia", "cloudbeds", "guestyBff", "streamline", "hostawayBff"];
  return bffEnabled.includes(pms);
};

export const shouldBffShowReviewResponder = (pms: string) => {
  return ["guestyBff"].includes(pms);
};

export const needsCheckinoutTab = (pms: string) => {
  return ["cloudbeds"].includes(pms);
};

export const isBffPmsMessagingEnabled = (pms: string) => {
  return ["guestyBff", "hostawayBff"].includes(pms);
};

import { createGlobalStyle } from "@doar/shared/styled";
export const MobileAppStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overscroll-behavior: none; /* Prevents the page from “bouncing” or scrolling */
    overflow: hidden;
  }
`;
