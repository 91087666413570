import { RegularUserPermissions } from "src/api";

export type UserPermissions = {
  admin: boolean;
  permissions?: RegularUserPermissions;
}

export function visible(permissions: UserPermissions | null, path: string): boolean {
  if (!permissions) {
    return false;
  }
  if (permissions.admin) {
    return true;
  }
  if (!permissions.permissions) {
    return false;
  }
  if (path.startsWith("/listings")) {
    return permissions.permissions.can_see_listings;
  }
  if (path.startsWith("/settings")) {
    return permissions.permissions.can_see_settings;
  }
  return true;
}

export function visibleListing(
  permissions: UserPermissions | null,
  listingId: number, 
  // providing a mapping of listing -> listingSetId for all of the listing id's
  // could be expensive, so we will do this on an as needed basis
  listingSet: () => string | null
): boolean {
  if (!permissions) {
    return false;
  }
  if (permissions.admin) {
    return true;
  }
  if (!permissions.permissions) {
    return false;
  }

  switch (permissions.permissions.listings.type) {
    case 'all':
      return true;
    case 'listings':
      return permissions.permissions.listings.listing_ids.includes(listingId);
    case 'listing_sets':
      let listingSetId = listingSet();
      return (listingSetId !== null) && permissions.permissions.listings.listing_set_ids.includes(listingSetId);
  }
}