import { Suspense, createContext, lazy, useEffect, useRef, useState } from "react";
import { Route, BrowserRouter as Router, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import { LoginStatus, RegularUserPermissions, WEBSOCKET_URL, authUserToken, describeLoginStatus, getUserInfo, getWebAccessTokensInitialized, setFirstTimeListingsReady } from "src/api";
import { useAppDispatch } from "src/redux/hooks";
import { setUserInfo } from "src/redux/slices/global";
import { setUnansweredMessages } from "src/redux/slices/messages";
import { getUser, isMobile} from "src/utils/";
import Preloader from "./components/preloader";
import PrivateRoute from "./components/private-route";
import { isBffEnabled } from "./helpers/helpers";
import CheckinCheckoutAdjustments from "./pages/CheckinCheckoutAdjustments";
import OutlookCallback from "./pages/outlookCallback";
import WebAccessInstructions from "./pages/webAccessInstructions";
import { UserPermissions } from "./utils/permissions";

// Lazy-loaded pages
const ReviewTasksPage = lazy(() => import("./pages/reviewTasks"));
const TasksPage = lazy(() => import("./pages/tasks"));
// const ContentPage = lazy(() => import("./pages/messages"));
const WidgetMessages = lazy(() => import("./pages/widgetMessages"));
const Listings = lazy(() => import("./pages/listings"));
const Reviews = lazy(() => import("./pages/reviews"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const Settings = lazy(() => import("./pages/settings"));
const Broadcast = lazy(() => import("./pages/broadcast"));
const Copilot = lazy(() => import("./pages/copilot"));
const Login = lazy(() => import("./pages/login"));
const ApiCreds = lazy(() => import("./pages/apiCreds"));
const ResetPassword = lazy(() => import("./pages/resetPassword"));
const Signup = lazy(() => import("./pages/signup"));
const Logout = lazy(() => import("./pages/logout"));
const Refer = lazy(() => import("./pages/refer"));
const FreeTrial = lazy(() => import("./pages/freeTrial"));
const UpgradePlan = lazy(() => import("./pages/upgradePlan"));
const CheckoutSuccess = lazy(() => import("./pages/checkoutSuccess"));
const CheckoutCancelled = lazy(() => import("./pages/checkoutCancelled"));
const PaymentFailed = lazy(() => import("./pages/paymentFailed"));
const AutomatedMessages = lazy(() => import("./pages/AutomatedMessages"));
const UpsellEvents = lazy(() => import("./pages/UpsellEvents"));
const MessageDashboard = lazy(() => import("./pages/message-dashboard"));
const MessageRouter = lazy(() => import("./pages/message-router"));
const GmailCallback = lazy(() => import("./pages/gmailCallback"));
// const outlookCallback = lazy(() => import("./pages/outlookCallback"));
const BffBookDemo = lazy(() => import("./pages/bffBookDemo"));
const Operator = lazy(() => import("./pages/operator"));
// Extend Window interface for custom properties
declare global {
  interface Window {
    Intercom: any;
    attachEvent?: (event: string, callback: () => void) => void;
    intercomSettings: {
      api_base?: string;
      app_id?: string;
      name?: string;
      email?: string;
      id?: string;
      plan?: string;
      pms?: string;
      numListings?: number;
    };
  }
}

function launchIntercom(): void {
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        // @ts-ignore
        i.c(arguments);
      };
      // @ts-ignore
      i.q = [];
      // @ts-ignore
      i.c = function (args) {
        // @ts-ignore
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/ipchktdn";
        var x = d.getElementsByTagName("script")[0];
        // @ts-ignore
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
}

const EXTERNAL_ROUTES = ["/login", "/signup", "/resetPassword", "/bffBookDemo"];

function externalRoute(path: string): boolean {
  return EXTERNAL_ROUTES.some((route) => path.startsWith(route));
}

export const UserPermissionsContext = createContext<UserPermissions | null>(null);

const App = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  // Use a ref to store token to prevent re-renders
  const tokenRef = useRef<string | null>(null);
  const [permissions, setPermissions] = useState<UserPermissions | null>(null);

  useEffect(() => {
    const tokenParams = searchParams.get("token");
    if (tokenParams) {
      tokenRef.current = tokenParams.split(",")[0];
    }
  }, [searchParams]);

  const [effectDone, setEffectDone] = useState(() => {
    console.log('Initializing effectDone state to false');
    return false;
  });
  console.log('App render - effectDone:', effectDone, 'location:', location.pathname);

  // Skip complex initialization for login/signup pages
  const isAuthPage = location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/resetPassword';

  // Reset effectDone on mount (runs once)
  useEffect(() => {
    console.log('App mounted, ensuring effectDone is false');
    if (!isAuthPage) {
      setEffectDone(false);
    } else {
      // Skip authentication setup for login/signup/reset pages
      setEffectDone(true);
    }
  }, [isAuthPage]);

  // Initialize WebSocket only after effectDone is true and not on auth pages
  const wsConnectedRef = useRef(false);
  const wsUrlRef = useRef<string | null>(null);
  // Set the WebSocket URL ref based on effectDone
  if (effectDone && !wsUrlRef.current && !isAuthPage) {
    wsUrlRef.current = WEBSOCKET_URL || "";
  }

  // Only connect to WebSocket when URL is available
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    wsUrlRef.current,
    {
      onOpen: () => {
        console.log("WebSocket connection established.");
        wsConnectedRef.current = true;
      },
      share: true,
      filter: () => false,
      retryOnError: true,
      shouldReconnect: () => true,
    }
  );

  // Set Intercom settings
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "ipchktdn",
  };

  useEffect(() => {
    // Skip authentication for login/signup/reset pages
    if (isAuthPage) {
      return;
    }

    console.log('setupUser effect running - token:', tokenRef.current);
    const setupUser = async () => {
      console.log("setupUser called - PATH:", location.pathname);

      // If user is on /logout, don't auto-navigate
      if (location.pathname.startsWith("/logout")) {
        console.log('Logout path detected, setting effectDone');
        setEffectDone(true);
        return;
      }

      //let loginStatus;
      //let userId = null;
      let authResult = null;
      // Attempt login via token in the URL
      if (tokenRef.current) {
        console.log('Using token from URL/localStorage');
        authResult = await authUserToken({ userToken: tokenRef.current });
        if (authResult) {
          //userId = authResult.userId;
          localStorage.setItem("besty_user_token", tokenRef.current);

          // If token is valid, loginStatus=1.
          // If the user is on reset password page, we treat them as not logged in (loginStatus=3).
          if (authResult.userId && !location.pathname.startsWith("/resetPassword")) {
            authResult.loginStatus = LoginStatus.ValidUserCanLogin;
          } else {
            authResult.loginStatus = LoginStatus.UserNotLoggedIn;
          }
        } 
      } else {
        let userResult = await getUser();
        if (userResult.userToken) {
          tokenRef.current = userResult.userToken;
          authResult = userResult;
        }
      }

      console.log(`Location: ${location.pathname}, authResult: (${JSON.stringify(authResult)}) ${authResult && describeLoginStatus(authResult.loginStatus)}`);

      if (!authResult) {
        navigate("/login");
        setEffectDone(true);
        return;
      }

      if (!authResult || authResult.loginStatus === LoginStatus.UserNotLoggedIn) {
        // if user tries external route while not logged in, let them proceed
        if (externalRoute(location.pathname)) {
          setEffectDone(true);
          return;
        }
        // otherwise, go to login
        navigate("/login");
        setEffectDone(true);
      } else if (authResult.loginStatus == LoginStatus.ValidUserNoPMSInfo) {
        console.log(`loginStatus=${authResult.loginStatus}, setting to /apiCreds`);
        const queryParams = new URLSearchParams(location.search);
        navigate("/apiCreds", {
          state: {
            enterInfo: true,
            userId: authResult.userId,
            userToken: tokenRef.current,
            pms: queryParams.get("pms"),
            apiError: queryParams.get("apiError") === "true",
          },
        });
        setEffectDone(true);
      } else if (authResult.loginStatus == LoginStatus.EscapiaUserWhoHasClickedISubmittedATicketButton) {
        console.log("loginStatus=5, setting to apiCreds w/ the 'waiting on Besty' message...");
        navigate("/apiCreds", {
          state: { enterInfo: false, userId: authResult.userId, userToken: tokenRef.current, pms: "escapia" },
        });
        setEffectDone(true);
      } else if (tokenRef.current) {
        // Logged in
        console.log(`loginStatus= ${authResult.loginStatus} so doing default`);
        setPermissions({admin: authResult.admin, permissions: authResult.permissions});

        // Pull user info, including fields from BOTH branches
        const userInfo = await getUserInfo({ userToken: tokenRef.current });

        // Save to Redux - batch the dispatches to reduce re-renders
        dispatch(setUserInfo({
          ...userInfo,
          // Add any additional fields needed
        }));

        // Only dispatch if we have unanswered messages
        if (userInfo.unansweredMessages > 0) {
          dispatch(setUnansweredMessages(userInfo.unansweredMessages));
        }

        let {
          name,
          username,
          userToken,
          plan,
          pms,
          numListings,
          bffReadyToSeeApp,
          trialHasPassed,
          isFirstTimeListingsReady,
        } = userInfo;

        // Update Intercom with user info
        window.intercomSettings = {
          ...window.intercomSettings,
          name,
          email: username,
          id: authResult.userId?.toString(),
          plan,
          pms,
          numListings,
        };

        // Edge cases after user is logged in
        const ppms = (pms || "").toLowerCase();
        const pmsThatNeedsEmailPassword = ["hostfully", "escapia"];

        // If plan is "off", direct user to marketing page
        if (plan === "off") {
          window.location.href = "https://getbesty.ai";
          return;
        }

        console.log("bffReadyToSeeApp: ", bffReadyToSeeApp);
        if (!bffReadyToSeeApp && isBffEnabled(pms)) {
          console.log("User is logged in, but bffReadyToSeeApp is false. Redirecting to /bffBookDemo");
          navigate("/bffBookDemo");
          setEffectDone(true);
          return;
        }

        // If user tries an external route while already logged in, redirect them to "/"
        if (externalRoute(location.pathname) || location.pathname.startsWith("/apiCreds")) {
          window.location.href = "/";
          setEffectDone(true);
          return;
        }

        // if (plan === "paid" && pastDue) navigate("/paymentFailed");
        //   ^-- can re-enable when going live

        if (plan === "free") {
          // For free plan, push to trial page if they're not there yet
          if (!location.pathname.startsWith("/trial")) {
            console.log("Redirecting to /trial");
            navigate("/trial");
          }
        } else if (plan === "trial" && trialHasPassed) {
          // Trial ended, prompt upgrade
          navigate("/upgrade");
        } else if (pmsThatNeedsEmailPassword.includes(ppms)) {
          // If user hasn't set up web access tokens for certain PMS
          const haveWebTokensBeenInitialized = authResult.userId
            ? await getWebAccessTokensInitialized(authResult.userId.toString())
            : false;
          if (!haveWebTokensBeenInitialized) {
            navigate("/webAccessInstructions", {
              state: { userId: authResult.userId, pms: ppms, token: tokenRef.current },
            });
            setEffectDone(true);
            return;
          }
        } else if (!isMobile()) {
          // For desktop users, if it's their first time, push them to listings or settings
          if (isFirstTimeListingsReady) {
            await setFirstTimeListingsReady({ userToken: tokenRef.current });
            navigate("/listings");
          }
          //} else if (isFirstTime) {
          // navigate("/settings");
          //}
        }
        setEffectDone(true);
      }
    };

    // Reset effectDone before running setupUser
    setEffectDone(false);
    setupUser();
    launchIntercom();
  }, [location.pathname, isAuthPage]);

  if (!effectDone) {
    return <Preloader />;
  }

  // If everything is loaded, show our routes
  return (
    <UserPermissionsContext.Provider value={permissions}>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/webAccessInstructions" element={<WebAccessInstructions />} />
          <Route path="/apiCreds" element={<ApiCreds />} />
          <Route path="/bffBookDemo" element={<BffBookDemo />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/checkoutSuccess"
            element={
              <PrivateRoute>
                <CheckoutSuccess />
              </PrivateRoute>
            }
          />
          <Route
            path="/checkoutCancelled"
            element={
              <PrivateRoute>
                <CheckoutCancelled />
              </PrivateRoute>
            }
          />
          <Route
            path="/paymentFailed"
            element={
              <PrivateRoute>
                <PaymentFailed />
              </PrivateRoute>
            }
          />
          <Route
            path="/trial"
            element={
              <PrivateRoute>
                <FreeTrial />
              </PrivateRoute>
            }
          />
          <Route
            path="/upgrade"
            element={
              <PrivateRoute>
                <UpgradePlan />
              </PrivateRoute>
            }
          />
          <Route
            path="/unanswered"
            element={
              <PrivateRoute>
                <MessageRouter />
              </PrivateRoute>
            }
          />
          <Route
            path="/widgetMessages"
            element={
              <PrivateRoute>
                <WidgetMessages />
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/listings"
            element={
              <PrivateRoute>
                <Listings />
              </PrivateRoute>
            }
          />
          <Route
            path="/reviews"
            element={
              <PrivateRoute>
                <Reviews />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/broadcast"
            element={
              <PrivateRoute>
                <Broadcast />
              </PrivateRoute>
            }
          />
          <Route
            path="/refer"
            element={
              <PrivateRoute>
                <Refer />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/copilot"
            element={
              <PrivateRoute>
                <Copilot />
              </PrivateRoute>
            }
          />
          <Route
            path="/review-tasks"
            element={
              <PrivateRoute>
                <ReviewTasksPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/tasks"
            element={
              <PrivateRoute>
                <TasksPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/checkinCheckoutAdjustments"
            element={
              <PrivateRoute>
                <CheckinCheckoutAdjustments />
              </PrivateRoute>
            }
          />
          <Route
            path="/automated-messages"
            element={
              <PrivateRoute>
                <AutomatedMessages />
              </PrivateRoute>
            }
          />
          <Route
            path="/message-dashboard"
            element={
              <PrivateRoute>
                <MessageDashboard />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/operator"
            element={
              <PrivateRoute>
                <Operator />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/upsell-analytics"
            element={
              <PrivateRoute>
                <UpsellEvents />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/messages"
            element={
              <PrivateRoute>
                <MessageRouter />
              </PrivateRoute>
            }
          />
          <Route
            path={"/gmailCallback"}
            element={
              <PrivateRoute>
                <GmailCallback />
              </PrivateRoute>
            }
          />
          <Route
            path={"/outlookCallback"}
            element={
              <PrivateRoute>
                <OutlookCallback />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </UserPermissionsContext.Provider>
  );
};

const Root = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default Root;
