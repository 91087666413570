import { AuthUserResponse, authUserToken, LoginStatus } from "../api/";

type UserAuthResponse = AuthUserResponse & {userToken: string}
const notLoggedIn = {userToken: '', loginStatus: LoginStatus.UserNotLoggedIn, userId: -1, admin: false, permissions: undefined};

async function getUser(): Promise<UserAuthResponse> {
    let userToken = localStorage.getItem("besty_user_token");

    // authenticate userId
    if (userToken) {
        const result = await authUserToken({ userToken });
        if (result) {
            return { ...result, userToken };
        } else {
            return notLoggedIn;
        }
    } // send to login page if user id not in local storage
    else {
        return notLoggedIn;
    }
};

export default getUser;
