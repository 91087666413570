import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setOutlookDataOnCallback } from "../api";
import { useAppSelector } from "../redux/hooks";
import { getUserInfo } from "../redux/slices/global";

const OutlookCallbackPage: React.FC = () => {
  const { userToken } = useAppSelector(getUserInfo);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<"processing" | "success" | "error">("processing");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const processAuthCode = async () => {
      try {
        // Get the code and state from URL parameters
        const code = searchParams.get("code");
        const state = searchParams.get("state");
        const error = searchParams.get("error");
        const error_description = searchParams.get("error_description");

        // If Microsoft returned an error, handle it
        if (error) {
          setStatus("error");
          setErrorMessage(error_description || "Authentication failed");
          return;
        }

        // If no code was received, show error
        if (!code) {
          setStatus("error");
          setErrorMessage("No authorization code received");
          return;
        }

        await setOutlookDataOnCallback({
          code,
          state: state ? state : "",
          userToken,
        });

        // Authentication successful
        setStatus("success");

        // Wait a moment to show success message before redirecting
        setTimeout(() => {
          // Redirect to the integration page with success flag
          navigate("/settings");
        }, 1500);
      } catch (error) {
        setStatus("error");
        setErrorMessage(error instanceof Error ? error.message : "An unexpected error occurred");
      }
    };

    processAuthCode();
  }, [searchParams, navigate, userToken]);

  return (
    <div className="outlook-callback-container">
      <div className="auth-status-card">
        {status === "processing" && (
          <>
            <div className="spinner"></div>
            <h2>Processing your Outlook authentication...</h2>
            <p>Please wait while we complete the connection.</p>
          </>
        )}

        {status === "success" && (
          <>
            <div className="success-icon">✓</div>
            <h2>Authentication Successful!</h2>
            <p>Your Outlook account has been connected successfully.</p>
            <p>Redirecting you back to the application...</p>
          </>
        )}

        {status === "error" && (
          <>
            <div className="error-icon">✗</div>
            <h2>Authentication Failed</h2>
            <p>{errorMessage}</p>
            <button className="retry-button" onClick={() => navigate("/outlook-integration")}>
              Back to Integration Page
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default OutlookCallbackPage;
